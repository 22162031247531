import {
  ControllerParams,
  CreateControllerFn,
} from 'yoshi-flow-editor-runtime';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/editor';
import settingsParams from './settingsParams';
import { getConfig } from '../../api/config.api';
import {
  dummyViewModelFactory,
  ServicePageViewModel,
  servicePageViewModelFactory,
} from '../../service-page-view-model/servicePageViewModel';
import { getSettingsValues } from 'yoshi-flow-editor-runtime/tpa-settings';
import { biDefaults, generateWidgetDefaults } from './bi/consts';
import {
  dummySchedulingViewModel,
  SchedulingSectionStatus,
  SchedulingSectionViewModel,
  schedulingSectionViewModelFactory,
} from '../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { getServiceSchedulingData } from './controller-logic/scheduling-fetcher';
import { ServiceType, TimezoneType } from '@wix/bookings-uou-types';
import { initUserMessage } from './controller-logic/init-user-message';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import { handleNavigation } from './controller-logic/handle-navigation';
import { sendNotification } from '../../api/notification.api';
import { schedulingLocationViewModelFactory } from '../../service-page-view-model/scheduling-location-view-model/schedulingLocationViewModel';
import {
  SchedulingTimezoneViewModel,
  schedulingTimezoneViewModelFactory,
} from '../../service-page-view-model/shceduling-timezone-view-model/schedulingTimezoneViewModel';
import { getServiceSlug } from './controller-logic/get-service-slug';
import { initializeViewModels } from './controller-logic/initialize-view-models';
import { SERVICE_PAGE_NAME } from './constants';

// https://stackoverflow.com/questions/63961803/eslint-says-all-enums-in-typescript-app-are-already-declared-in-the-upper-scope
// eslint-disable-next-line no-shadow
enum ScheduleStatus {
  UNDEFINED = 'UNDEFINED',
  CREATED = 'CREATED',
  CANCELLED = 'CANCELLED',
}

const createController: CreateControllerFn = async ({
  flowAPI,
  appData,
}: ControllerParams) => {
  const errorReporter = appData?.ravenReporter || (() => null);

  const setProps = flowAPI.controllerConfig.setProps;
  const t = flowAPI.translations.t;
  const { controllerConfig, httpClient } = flowAPI;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const settings = getSettingsValues(publicData, settingsParams);

  return {
    async pageReady() {
      const { platformAPIs, appParams, wixCodeApi } = controllerConfig;
      const instance = appParams.instance as string;
      const wixSdkAdapter: WixOOISDKAdapter = new WixOOISDKAdapter(
        wixCodeApi,
        platformAPIs,
        appParams,
        controllerConfig.compId,
      );

      const isSEO = wixSdkAdapter.isSEO();
      const isBookingCalendarInstalled = await wixSdkAdapter.isBookingCalendarInstalled();

      const { isPreview, isSSR, isEditor, isViewer } = flowAPI.environment;

      let scheduleViewModel: SchedulingSectionViewModel = {
        status: SchedulingSectionStatus.LOADING,
        isBookable: false,
      };
      let locationViewModel, getServiceSchedulingDataByLocation;
      let timezoneViewModel: SchedulingTimezoneViewModel;
      let viewModel: ServicePageViewModel,
        navigateToCalendar = () => {};

      const experiments = await flowAPI.getExperiments();

      const initWidget = async () => {
        if (isViewer || isPreview) {
          const shouldUseSdkAdapterToGetServiceSlug = experiments.enabled(
            'specs.bookings.UseSdkAdapterToGetServiceSlug',
          );
          const serviceSlug = shouldUseSdkAdapterToGetServiceSlug
            ? await wixSdkAdapter.getServiceSlug(SERVICE_PAGE_NAME)
            : await getServiceSlug(wixCodeApi, isPreview);

          const config = await httpClient.request(getConfig, {
            headers: { authorization: instance },
          })(serviceSlug, isPreview);

          flowAPI.biLogger &&
            flowAPI.biLogger.util.updateDefaults({
              ...biDefaults,
              ...generateWidgetDefaults(appParams, platformAPIs, isEditor),
              serviceId: config.serviceDto.id,
              service_id: config.serviceDto.id,
              type: config.serviceDto.type,
            });

          if (experiments.enabled('specs.bookings.SeoIntegration')) {
            await wixCodeApi.seo.renderSEOTags({
              itemType: ITEM_TYPES.BOOKINGS_SERVICE,
              itemData: {
                serviceResponse: config.SEO.serviceResponse,
                bookingsPolicyDto: config.bookingPolicyDto,
              },
              seoData: config.SEO.serviceResponse?.service?.advancedSeoData,
            });
          }
          const isOnlyCreatedSchedule = experiments.enabled(
            'specs.bookings.UseActiveScheduleOnly',
          );
          const isUoUMultiLocationAllLocationsEnabled = experiments.enabled(
            'specs.bookings.UoUMultiLocationAllLocations',
          );
          const isCourse = config.serviceDto.type === ServiceType.COURSE;
          const serviceSchedule = isOnlyCreatedSchedule
            ? config.SEO.serviceResponse?.schedules?.find(
                (schedule) => schedule.status === ScheduleStatus.CREATED,
              )
            : config.SEO.serviceResponse?.schedules?.[0];
          const firstSessionStart = serviceSchedule?.firstSessionStart;
          const lastSessionEnd = serviceSchedule?.lastSessionEnd;
          const queryLocationId = wixCodeApi.location.query?.location;
          const selectedLocation = config.serviceDto.info.locations?.find(
            (serviceLocation) =>
              serviceLocation.businessLocation?.id === queryLocationId,
          )
            ? queryLocationId
            : undefined;
          const initViewModels = initializeViewModels({
            config,
            t,
            experiments,
            selectedLocation,
            isSEO,
            isBookingCalendarInstalled,
          });
          viewModel = initViewModels.viewModel;
          locationViewModel = initViewModels.locationViewModel;
          timezoneViewModel = initViewModels.timezoneViewModel;

          navigateToCalendar = () => {
            handleNavigation({
              config,
              isPreview,
              wixSdkAdapter,
              locationId: locationViewModel.currentLocation,
              timezone: timezoneViewModel.viewTimezone,
              onNavigationFailed: ({ failReasons }) => {
                httpClient.request(sendNotification, {
                  headers: { authorization: instance },
                })(failReasons, config.serviceDto.id);
                flowAPI.biLogger &&
                  flowAPI.biLogger.bookingsCantBookGroupsMessage({
                    widget_name: 'service_page',
                    referralInfo: 'service_page',
                    isPreview,
                    failReason: JSON.stringify(failReasons),
                  });
                showUserMessage();
              },
            });
          };

          getServiceSchedulingDataByLocation = (locationId: string) => {
            locationViewModel = schedulingLocationViewModelFactory({
              serviceInfoDto: config.serviceDto.info,
              selectedLocation: locationId,
              isUoUMultiLocationAllLocationsEnabled,
              t,
            });
            scheduleViewModel = {
              status: SchedulingSectionStatus.LOADING,
              isBookable: viewModel.body.isBookable,
            };
            setProps({
              locationViewModel,
              scheduleViewModel,
            });
            getServiceSchedulingData({
              config,
              settings,
              httpClient,
              instance,
              isOnlyCreatedSchedule,
              locationId,
            })
              .then((schedule) => {
                const changeTimezoneCallback = (timezoneType: TimezoneType) => {
                  timezoneViewModel = schedulingTimezoneViewModelFactory({
                    businessInfo: config.businessInfo,
                    selectedTimezoneType: timezoneType,
                    isBookingCalendarInstalled,
                  });
                  viewModel = servicePageViewModelFactory({
                    config,
                    t,
                    experiments,
                    viewTimezone: timezoneViewModel.viewTimezone,
                    isSEO,
                  });
                  scheduleViewModel = schedulingSectionViewModelFactory({
                    isBookable: viewModel.body.isBookable,
                    catalogSessionsDto: schedule?.sessions,
                    businessInfo: config?.businessInfo,
                    viewTimezone: timezoneViewModel.viewTimezone,
                    isCourse,
                    firstSessionStart,
                    lastSessionEnd,
                    t,
                  });
                  setProps({
                    viewModel,
                    timezoneViewModel,
                    scheduleViewModel,
                  });
                };
                scheduleViewModel = schedulingSectionViewModelFactory({
                  isBookable: viewModel.body.isBookable,
                  catalogSessionsDto: schedule?.sessions,
                  businessInfo: config?.businessInfo,
                  viewTimezone: timezoneViewModel.viewTimezone,
                  isCourse,
                  firstSessionStart,
                  lastSessionEnd,
                  t,
                });
                setProps({
                  locationViewModel,
                  scheduleViewModel,
                  timezoneViewModel,
                  changeTimezoneCallback,
                });
              })
              .catch((e) => {
                console.log('Getting schedules failed ', e);
                scheduleViewModel = {
                  status: SchedulingSectionStatus.FAILED,
                  isBookable: false,
                };
                setProps({
                  scheduleViewModel,
                });
              });
          };

          if (!isSSR) {
            getServiceSchedulingDataByLocation(
              locationViewModel.currentLocation,
            );
          }
        } else {
          const isEditorX =
            flowAPI.controllerConfig.config.style.styleParams.booleans
              .responsive;
          // const experiments = await flowAPI.getExperiments();
          // const isOwnerGalleryEnabled = experiments.enabled(
          //   'specs.bookings.OwnerGallery',
          // );
          const isOwnerGalleryEnabled = true;
          viewModel = dummyViewModelFactory({
            t,
            isEditorX,
            isOwnerGalleryEnabled,
          });
          const dummyBusinessInfo = {
            timeZone: 'UTC',
            regionalSettingsLocale: flowAPI.environment.language,
            dateRegionalSettingsLocale: flowAPI.environment.language,
          };
          scheduleViewModel = dummySchedulingViewModel({
            t,
            businessInfo: dummyBusinessInfo,
            scheduleDays: settings.scheduleDays,
          });

          flowAPI.biLogger &&
            flowAPI.biLogger.util.updateDefaults({
              ...biDefaults,
              ...generateWidgetDefaults(appParams, platformAPIs, isEditor),
            });
        }

        const { userMessage, showUserMessage } = initUserMessage(setProps);

        setProps({
          navigateToCalendar,
          scheduleViewModel,
          locationViewModel,
          changeLocationCallback: getServiceSchedulingDataByLocation,
          viewModel,
          userMessage,
        });
      };
      await initWidget();

      const isLocationChangeFired = experiments.enabled(
        'specs.bookings.LocationChangeFired',
      );

      if (isLocationChangeFired) {
        wixCodeApi.location.onChange(async () => {
          await initWidget();
        });
      }
    },
    updateConfig($w, config) {
      const updatedPublicData = config.publicData.COMPONENT || {};
      const updatedSettings = getSettingsValues(
        updatedPublicData,
        settingsParams,
      );
      if (settings.scheduleDays !== updatedSettings.scheduleDays) {
        const dummyBusinessInfo = {
          timeZone: 'UTC',
          regionalSettingsLocale: flowAPI.getSiteLanguage('en'),
        };

        const scheduleViewModel = dummySchedulingViewModel({
          t,
          businessInfo: dummyBusinessInfo,
          scheduleDays: updatedSettings.scheduleDays,
        });

        setProps({
          scheduleViewModel,
        });
      }
    },
  };
};

export default createController;
